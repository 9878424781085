import React from "react";
import { useRenderContext } from "@wq/react";
import { View } from "@wq/material";
import { Analyst } from "@wq/analyst";
import { DataNav } from "./DataDetail";
export default function Data() {
    const context = useRenderContext();
    return (
        <View
            sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
            }}
        >
            <DataNav context={context} />
            <Analyst key={context.year} />
        </View>
    );
}
