import { format } from "@wq/material";

export function context(ctx, routeInfo) {
    if (routeInfo.name === "report_edit:new" && this.app.user) {
        const samplers = this.app.models.sampler.objects
                .toRefArray()
                .filter(
                    (row) =>
                        row.users_id &&
                        row.users_id.includes(this.app.user.username),
                ),
            sampler_id = samplers.length === 1 ? samplers[0].id : null;
        return {
            user_id: this.app.user.username,
            project_id: "redrwtch",
            sampler_id,
            recorded: format.datetime(new Date()),
            source: "web",
            event: {
                site: { slug: null },
                date: format.date(new Date()),
                type: { slug: "routine" },
            },
        };
    }
    if (routeInfo.page_config.analyst) {
        const context =
            routeInfo.params && routeInfo.params.year
                ? { year: routeInfo.params.year }
                : {};
        if (routeInfo.name === "data") {
            context.start_year = "1950";
            context.end_year = new Date().getFullYear();
            context.event_count = 100000;
        }
        return context;
    }
}

export function makeProjectForm(project, parameters) {
    const paramById = {};
    for (const param of parameters) {
        paramById[param.id] = param;
    }
    return project.fieldsets.map((fieldset, i) => ({
        type: "group",
        name: fieldset.slug,
        label: fieldset.name,
        children: fieldset.parameters.map((fp) => {
            const param = paramById[fp.parameter_id];
            const field = {
                name: fp.parameter_id,
                label: param.label,
                type: param.is_numeric ? "decimal" : "string",
                hint: param.description,
            };
            if (param.choices && param.choices.length) {
                field.choices = param.choices;
                field.type = "select one";
            }
            if (fp.required === "required") {
                field.required = true;
            }
            if (param.is_numeric) {
                field.control = { appearance: "qualifier-input" };
            }
            return field;
        }),
    }));
}
