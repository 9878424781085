import React from "react";
import {
    useRenderContext,
    useRouteInfo,
    useReverse,
    RelatedLinks,
} from "@wq/react";
import {
    TabGroup,
    TabItem,
    View,
    ScrollView,
    Fab,
    HorizontalView,
    Typography,
    ButtonLink,
} from "@wq/material";
import { AutoMap } from "@wq/map";
import { MapProvider, HighlightPopup } from "@wq/map-gl";
import PropertyTable from "../components/PropertyTable";
import { Analyst } from "@wq/analyst";

export default function SiteDetail() {
    const reverse = useReverse(),
        context = useRenderContext(),
        { page, item_id, page_config } = useRouteInfo(),
        editUrl = reverse(`${page}_edit`, item_id);

    return (
        <View
            sx={{
                "& > div, & > div > div, &  > div > div > div": {
                    overflow: "hidden",
                },
                span: {
                    maxHeight: "50px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            }}
            style={{ flex: 1, display: "flex", overflow: "hidden" }}
        >
            <TabGroup sx={{ minHeight: 72 }}>
                <TabItem value="data" icon="analyst" label="Data">
                    <DataNav context={context} />
                    <Analyst key={context.year} />
                </TabItem>
                <TabItem value="map" icon="map" label="Location">
                    <MapProvider>
                        <AutoMap mapId="map" context={context}>
                            <HighlightPopup inMap />
                        </AutoMap>
                        <HighlightPopup />
                    </MapProvider>
                </TabItem>
                <TabItem value="info" icon="detail" label="info">
                    <ScrollView>
                        <PropertyTable
                            values={context}
                            form={context.router_info.page_config.form}
                        />
                        <RelatedLinks
                            id={context.id}
                            model={context.router_info.page_config.name}
                        />
                    </ScrollView>
                    {page_config.can_change !== false && (
                        <Fab icon="edit" to={editUrl} />
                    )}
                </TabItem>
            </TabGroup>
        </View>
    );
}

export function DataNav({ context }) {
    const {
            router_info: { name: routeName },
            id,
            event_count,
            start_year,
            end_year,
            year = new Date().getFullYear(),
        } = context,
        reverse = useReverse();
    if (event_count < 2000) {
        return null;
    }
    const prevYear = +year > start_year && Math.min(+year - 1, end_year),
        nextYear = +year < end_year && Math.max(+year + 1, start_year);
    return (
        <HorizontalView>
            {prevYear ? (
                <ButtonLink
                    variant="outlined"
                    to={reverse(routeName, id, { year: prevYear })}
                >
                    ⬅️ {prevYear}
                </ButtonLink>
            ) : (
                <View />
            )}
            <Typography>
                <strong>{year}</strong>
            </Typography>
            {nextYear ? (
                <ButtonLink
                    variant="outlined"
                    to={reverse(routeName, id, { year: prevYear })}
                >
                    {nextYear} ➡️
                </ButtonLink>
            ) : (
                <View />
            )}
        </HorizontalView>
    );
}
